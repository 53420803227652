.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  padding-top: 24%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modalcontent {
  background-color: #fefefe;
  margin: auto;
  padding: 13px;
  border: 1px solid #888;
  width: 24%;
  text-align: center;
  border-radius: 6px;
}
.modalcontent p{
    padding: 0;
    margin: 0;
    font-size: 15px;
    /* font-weight: bold; */
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
