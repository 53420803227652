.img_size{
    height: 20px;
    width: 20px;  
    margin-right: 8px;
}
.text_underline {
    position: relative;
}
.text_underline:before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--cerulean);
    position: absolute;
    bottom: 3px;
}
.link_txt_color {
    color: var(--cerulean);
}
