.general_text_box_py_8{
    /* padding: 8px 22px !important; */
    min-width: 140px;
}

.btn_size{
    width: auto !important;
    margin-right: 4px;
    align-content: right;
}

.submit_row {
    padding: 12px 14px;
    margin: 0 0 20px;
    background: #f8f8f8;
    border: 1px solid #eee;
    border-radius: 4px;
    text-align: right;
    overflow: hidden;
}

.textinput_size{   
    /* height: 36px; */
    font-size: 14px;   
    border-color: #E7E7E7;
    min-height: 40px;
}
.textinput_size input {   
    padding-top: 2px;
    padding-bottom: 2px;
}


.paginator_showall{
    border: none;
    background: white;
    color: #00a5d9;
    vertical-align: top;   
    height: 32px;
}

.head_cells{
    /* background-color: #dcdcdc; */
}
.tr_check {
    background-color: #E9FAFF;
}

.delete_confirmation{
    background-color: #ba2121;
}

.delete_confirmation_cancel{
    background: #ddd;
}
.btn_gp button {
    min-height: 36px;;
}
.btn_gp button:not(:first-child) {
    min-width: 204px !important;
}
.btn_w_120 {
    width: 120px;
}
.btn_h_36 {
    min-height: 36px;
}
.input_h_36 {
    min-height: 36px;    
    padding-top: 2px;
    padding-bottom: 2px;
}
.input_h_40 {
    min-height: 40px;  
}
.select_box {
    /* width: 200px; */
    border-color: var(--granite);
    border-radius: 6px;
    background-image: url("../../../assets/images/down_arrow.png");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 13px;
    background-size: 12px 7px;
    background-color: transparent;
}
.select_box:focus {
    border-color: var(--cerulean);
    box-shadow: none;
    background-color: transparent;
}
.select_box option {
    background-color: #fff;
}
.paginate_select_box {
    width: 50px;
    border-color: var(--cerulean);
    border-radius: 6px;
    background-image: url("../../../assets/images/down_arrow.png");
    background-repeat: no-repeat;
    background-position-x: 88%;
    background-position-y: 12px;
    background-size: 12px 7px;
    background-color: transparent;
    padding: 5px;
}
.paginate_select_box:focus {
    border-color: var(--cerulean);
    box-shadow: none;
    background-color: transparent;
}
.paginate_select_box option {
    background-color: #fff;
}
@media screen and (max-width: 1024px) {
    .select_box {
        width: 200px;
    }
}
.btn_disabled {
    background: #F2F2F2;
    color: rgba(51, 51, 51, 0.3);
    border-color: #F2F2F2;
}
.btn_keep {
    background: #E98300;
    border-color: #E98300;
}
.btn_export {
    background: rgba(233, 131, 0, 0.2);
    color: #E98300;
    width: auto;
    min-width: 130px;
    border-color: rgba(233, 131, 0, 0.2);
}
.custom_search_btn img:first-child {
    width: 12px;
    left: 18px;
}

.hr_line {
    margin: 32px 0 24px;
    border-style: dashed;
    border-color:#D9D9D9;
}
.file_input_btn {
    background: #FBFBFB;
    border-color: #D6E4EC;
}
.file_input_btn input {
    padding-top: 8px;
    padding-bottom: 8px;
}
.custom_tb_height {
    max-height: 280px;
}
.custom_file_input {
    padding: 10px;
    background: #FBFBFB;
    border: 1px solid #D6E4EC;
    border-radius: 5px;
    cursor: pointer;
}
.custom_choose_file{
    padding: 3px 6px;
    border: 1px solid #959395;
    color: #333333;
    font-weight: 400;
    border-radius: 5px;
}
.th_name_word_break{
    word-break: break-word;
}
.th_name_width{
    width: 25%;
}

.td_adjust_height{
    display: flex;
    min-height: 38px;
    align-items: center;
    padding: 9px 0 10px 0.75rem;
    color: #495057;
    font-size: 1rem
}

.upn_text_warp{
    word-break: break-all;
}