.paginationBttns{
    list-style: none;
    display: flex;
    margin-bottom: 0;
}
.paginationBttns a {
    padding: 5px;
    margin: 4px;
    border-radius: 5px;
    /* border: 1px solid #447e9b; */
    color: var(--cerulean);
    cursor: pointer;
    min-width: 27px;
    display: inline-block;
    text-align: center;
    background-color: rgba(0, 165, 217, 0.1);
    font-weight: bold
}
/* .paginationBttns a:hover {
    color: white;
    background-color: #00a5d9;
} */
.paginationBttns .paginationActive a:hover,
.paginationBttns .paginationActive a,
.paginationBttns a:hover {
    color: white;
    background-color: var(--cerulean);
}