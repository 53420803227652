.select_box {
  width: 100%;
  padding: 4px 16px;
  height: 40px;
  position: relative;
  cursor: pointer;
  background: #fbfbfb;
  border: 0.678715px solid #d6e4ec;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
}
.select_box_radius {
  background: var(--pure-white);
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-color: #00a5d9 !important;
}
.select_box > div {
  width: 100%;
  overflow: hidden;
}
.list {
  background: var(--pure-white);
  width: 100%;
  list-style: none;
  padding: 0;
  /* margin-top: 9px; */
  left: 0;
  position: absolute;
  z-index: 1000;
  border: 0.68px solid #d6e4ec;
}
.showbox {
  transition: all 0.2s ease-in-out;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.hidebox {
  visibility: hidden;
  opacity: 0;
}
.list ul li {
  padding: 4px 16px 4px 16px;
  cursor: pointer;
  min-height: 38px;
  display: flex;
  align-items: center;
  position: relative;
}
.list ul.select_all_list li {
  padding-left: 46px;
}
.list ul.select_all_list > li:first-child {
  padding-left: 16px;
}
.list ul.select_all_list li > label {
  position: relative;
}

.list ul.select_all_list li:before {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  left: 24px;
  top: 0;
  border: 0.3px dashed rgba(0, 165, 217, 0.3);
}
.list ul.select_all_list li:last-child:before {
  height: 50%;
}
.list ul.select_all_list > li:first-child:before {
  top: auto;
  height: 24%;
  bottom: 0;
}
.list ul.select_all_list li > label:after {
  content: '';
  position: absolute;
  width: 19px;
  height: 1px;
  left: -19px;
  z-index: 9;
  top: 10px;
  border: 0.3px dashed rgba(0, 165, 217, 0.3);
}
.list ul.select_all_list > li:first-child > label:after {
  content: initial;
}

.list ul > li > label > span:first-child > span > span {
  border-color: #00a5d9 !important;
}
.list ul > li > label > span:nth-child(2) {
  display: grid !important;
}
.list ul > li > label {
  align-items: start !important;
}
.list ul > li:hover,
.list ul > li:focus {
  background-color: #e6e6e6;
}

.arrows {
  width: 12px;
  position: absolute;
  right: 12px;
  top: 15px;
  z-index: 2;
}
.search_icon {
  width: 14px;
}
.min_height {
  max-height: 170px;
  overflow-y: auto;
  margin-bottom: 0px;
}
.placeholder {
  color: var(--granite);
}
.footer {
  border-top: 0.68px solid #d6e4ec;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 14px 14px 14px 0px;
}
.footer > button {
  width: 56px !important;
  min-width: 56px !important;
}

input.search_text {
  /* width: 9.5% !important; */
  background: transparent !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 0 !important;
}
div.search_text_width,
.modify_search_text_width {
  width: auto !important;
}
.search_text_width > input {
  width: 8px !important;
}
.modify_search_text_width > input {
  /* width: auto !important; */
}
.search_text:focus {
  box-shadow: none !important;
}
.search_text::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px !important;
}
.search_text::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px !important;
}
.search_text:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px !important;
}
.search_text:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px !important;
}
.selected_item {
  background: var(--alice-blue);
  border-radius: 20px;
  padding: 5px 13px;
  margin-right: 4px;
  /* position: relative; */
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08);
  color: var(--cerulean);
}
.pl_53 {
  padding-left: 53px !important;
}

.selected_item:after {
  content: '';
  width: 70px;
  height: 100%;
  background: linear-gradient(-90deg, #fbfbfb 40%, rgba(251, 251, 251, 0) 100%);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  border-top-right-radius: 6px;
} 
.search_text::placeholder {
  color: var(--dune);
}
.search_text:-ms-input-placeholder {
  color: var(--dune);
}
.search_text::-ms-input-placeholder {
  color: var(--dune);
}
.gray {
  background: var(--light-grey-6) !important;
  border-color:var(--light-grey-6) !important; ;
}
