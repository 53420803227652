
.content-large { 
  /* width: 100% ;   */
  width: calc(100% - 254px); 
  margin-left: 254px;
}
.content-small {  
  width: calc(100% - 65px); 
  margin-left: 65px;
}
#sidebar {
    color: var(--pure-white); 
    z-index: 2;      
    position: fixed; 
    height: 100vh;
    display: grid;
    top: 0;
    font-size: 12px;
}
.sidebar-bg{
  background-color: var(--cerulean);
}
.sidebar-bg-green{
  background-color: #69A926;
}
.light_grey_4 { 
  color: var(--light-grey-4);
}
#sidebar.sidebar-large {
  width: 254px;
}
#sidebar.sidebar-small {
  width: 65px;  
  /* position: absolute !important; */
}
.sidebar-wrap {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  /* background-color: var(--cerulean); */
}
.sidebar-wrap.sidebar-wrap-large{
  width: 254px;  
}
.sidebar-wrap.sidebar-wrap-small{
  width: 65px;  
}
#sidebar .logo-sec a { 
  color: var(--pure-white);
}
#sidebar .logo-sec a:hover { 
  text-decoration: none;
}
.logo_link {
  display: flex;
  justify-content:center;
  align-items:center;
}
.logo_link>img {
  padding-right: 8px;
}
.logo_link>span {
line-height: 24px;
}
#sidebar.sidebar-small ul li span {
  display: none;
}
#sidebar.sidebar-small .logo-sec {
  height: 45px;
  margin: 20px 10px 15px;
}
#sidebar.sidebar-small .logo-sec>* {
  display: none !important;
}
#sidebar.sidebar-large.over-height {
  overflow-y: auto;
}
#sidebar .sidebar-menu {
  list-style: none;
  padding-left: 0;
}
#sidebar .sidebar-menu>li {
  position: relative;
  margin-bottom: 8px;
}
#sidebar .sidebar-menu>li>a {
  padding: 16px 10px;
  display: flex;
}
#sidebar .sidebar-menu li a.is-active {
  text-decoration: underline;
}
/* #sidebar .sidebar-menu>li.active>a,
#sidebar .sidebar-collapse:hover,
#sidebar .sidebar-menu>li>a.is-active, */
.sidebar-bg .sidebar-menu>li>a:hover {
  background: #027EC3;
}
.sidebar-bg-green .sidebar-menu>li>a:hover {
  background: #69A926;
}
#sidebar .sidebar-menu li a {
  color: var(--pure-white);
  cursor: pointer;
}
.sidebar-collapse img,
#sidebar .sidebar-menu>li img {
  /* width: 20px; */
  margin-right: 8px;
}
#sidebar.sidebar-small .sidebar-collapse img,
#sidebar.sidebar-small .sidebar-menu>li img {
  margin: 0 auto;
  display: block;
}
#sidebar.sidebar-small .sidebar-menu>li:first-child img {
  height:20px;
}
#sidebar .logo-sec {
  border-bottom: 1px solid var(--pure-white);
  margin: 20px 8px 15px;
  padding-bottom: 8px;
}
#sidebar .sidebar-submenu {
  padding: 0 10px 10px 30px;
}
#sidebar .sidebar-submenu p {
  margin-bottom: 8px;
}
#sidebar .sidebar-submenu p>span {
  width: 20px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: text-top;
  text-align: right;
  margin-top: -1px;
}
#sidebar .sidebar-submenu p img {
  margin-right: 0;
}
#sidebar .sidebar-submenu p:first-child {
  margin-top: 8px;
}
#sidebar .sidebar-submenu>ul {
  list-style: none;
  padding-left: 28px;
  margin-bottom: 16px;
}
#sidebar .sidebar-submenu>ul:last-child {
  margin-bottom: 0;
}
#sidebar .sidebar-submenu>ul li {
  padding-bottom: 4px;
}
#sidebar .sidebar-submenu>ul li:last-child {
  padding-bottom: 0;
}
#sidebar .sidebar-collapse {
  align-self: flex-end;
  cursor: pointer;
}
#sidebar .sidebar-collapse>div {
  padding: 15px;
  display: flex;
  align-items: center;
}
#sidebar .show {
  display: block;
}
#sidebar .hide {
  display: none;
}
.sidebar-small .sidebar-menu>li>a {
  padding: 15px 0 !important;
  text-align: center;
}
#sidebar .submenu-small {
  display: none;
  padding: 10px 10px 10px 15px;
  position: absolute;
  width: 250px;
  top: 0;
  left: 65px;
  background: #0292E3;
  text-align: left;
}
#sidebar.sidebar-small .sidebar-menu>li:hover .submenu-small {
  display: block !important;
}
#sub_menu_list {
  margin-left: 42px;
}
/* #roleplay_evaluation {
  background: #027EC3;
} */
#sidebar .bg-darkGreen {
  background: #009933;
}

#sidebar .bg-darkBlue {
  background: #027EC3;
}